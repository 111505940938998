import LogoNoText from './svg/mycasino-logo-no-text.svg';
import LogoVertical from './svg/mycasino-logo-vertical.svg';
import LogoHorizontal from './svg/mycasino-logo-horizontal.svg';

import LogoNoTextNegative from './svg/mycasino-logo-no-text-negative.svg';
import LogoVerticalNegative from './svg/mycasino-logo-vertical-negative.svg';
import LogoHorizontalNegative from './svg/mycasino-logo-horizontal-negative.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: LogoHorizontal,
  HorizontalInverse: LogoHorizontalNegative,
  Vertical: LogoVertical,
  VerticalInverse: LogoVerticalNegative,
  Icon: LogoNoText,
  IconInverse: LogoNoTextNegative,
};
