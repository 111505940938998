import type { Localized } from '../locale';

export const LanguageLocalesMap: Localized<string> = {
  en: 'en-CH',
  fr: 'fr-CH',
  de: 'de-CH',
  it: 'it-CH',

  // Not used
  et: 'et-EE',
  sv: 'sv-SE',
  fi: 'fi-FI',
  lv: 'lv-LV',
  ru: 'ru-RU',
  es: 'es-ES',
};
