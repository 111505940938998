import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import type { Locale } from 'date-fns';
import type { Localized } from '../locale';

export const LanguageCodeToLocaleMap: Localized<Locale> = {
  en: enGB,
  it: it,
  fr: fr,
  de: de,

  // Not used
  sv: enGB,
  fi: enGB,
  ru: enGB,
  lv: enGB,
  es: enGB,
  et: enGB,
};
