import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  Icon: Color.HeadingText,
  IconFocus: Color.Primitive.Primary,
  IconFocusBackground: Color.Primitive.Primary + '20',
};
