import { MyCasinoColor } from '@pafcloud/style';

export const Colors = {
  Text: MyCasinoColor.Lighter,
  TextHover: MyCasinoColor.White,
  TextActive: MyCasinoColor.White,
  TextShadow: `0 1px ${MyCasinoColor.Shadow}`,

  Icon: 'currentColor',
  IconActive: MyCasinoColor.Primary,

  Background: undefined,
  BackgroundHover: 'rgba(0,0,0,0.2)',
  BackgroundActive: MyCasinoColor.Black,

  ItemDivider: '1px solid rgba(0,0,0,0.08)',
  ItemActiveShadow: `4px 0 0 ${MyCasinoColor.Primary}`,

  SubMenuBackground: MyCasinoColor.Black,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
