import { MyCasinoColor } from '@pafcloud/style';

export const Colors = {
  Text: MyCasinoColor.Dark,
  MessageSeenText: MyCasinoColor.Neutral,
  PaginationText: MyCasinoColor.BodyText,

  BorderColor: MyCasinoColor.Shadow,

  Highlight: `${MyCasinoColor.Primary}0D`, //5% opacity
  HighlightBorder: MyCasinoColor.Primary,
};
