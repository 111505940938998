import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  Text: Color.Primitive.PrimaryContrast,
  Link: Color.Primitive.Secondary,
  Bullets: Color.Primitive.SecondaryTint,
  Background: Color.Primitive.Primary,
  BrandLogoText: Color.Primitive.PrimaryContrast,
};
