import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const MyCasinoBrandColors = {
  Paper: '#eff0e9',

  // Primary Colors
  Primary: '#cc0000',
  PrimaryLight: '#990000',
  PrimaryHighlight: '#e60000',

  // Alternate Colors
  Alternate: '#ffce2e',
  AlternateLight: '#fac000',
  AlternateHighlight: '#ffd447',

  // Neutral Colors
  Black: '#222829',
  Dark: '#4b5253',
  Darker: '#343b3c',
  Golden: '#dfc46a',
  Light: '#e0e4e3',
  Lighter: '#c7cbcb',
  Neutral: '#929797',
  White: '#ffffff',

  // Extra
  Aux: '#cc0000',
  Green: '#59a82e',
  LuzernGold: '#cd9b27',
};

const Primitive: PrimitiveColorType = {
  Primary: MyCasinoBrandColors.Primary,
  PrimaryTint: MyCasinoBrandColors.PrimaryLight,
  PrimaryContrast: MyCasinoBrandColors.White,

  Secondary: MyCasinoBrandColors.Alternate,
  SecondaryTint: MyCasinoBrandColors.AlternateLight,
  SecondaryContrast: MyCasinoBrandColors.Black,

  Accent: MyCasinoBrandColors.Primary,
  AccentTint: MyCasinoBrandColors.PrimaryLight,
  AccentContrast: MyCasinoBrandColors.White,

  Gradient: MyCasinoBrandColors.Primary,
  GradientTint: MyCasinoBrandColors.PrimaryLight,
  GradientContrast: MyCasinoBrandColors.White,
};

const TextColors: TextColorType = {
  HeadingText: MyCasinoBrandColors.Black,
  BodyText: MyCasinoBrandColors.Dark,
  MutedText: MyCasinoBrandColors.Neutral,
  HighlightedText: MyCasinoBrandColors.Black,
  LinkText: MyCasinoBrandColors.Primary,
  ErrorText: MyCasinoBrandColors.Primary,
  DisabledText: '#AFB3B3',
};

const Surface: SurfaceColorType = {
  Base: {
    Background: MyCasinoBrandColors.White,
    Foreground: MyCasinoBrandColors.Black,
    Dimmed: createTransparentColor(MyCasinoBrandColors.Black, 0.15),
  },
  Nested: {
    Background: '#f0f3f3',
    Foreground: MyCasinoBrandColors.Black,
    Dimmed: createTransparentColor(MyCasinoBrandColors.Black, 0.15),
  },
  Disabled: {
    Background: MyCasinoBrandColors.Light,
    Foreground: MyCasinoBrandColors.Neutral,
    Dimmed: createTransparentColor(MyCasinoBrandColors.Darker, 0.15),
  },
  Floating: {
    Background: MyCasinoBrandColors.White,
    Foreground: MyCasinoBrandColors.Black,
    Dimmed: createTransparentColor(MyCasinoBrandColors.Black, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: MyCasinoBrandColors.Green,
  SuccessContrast: MyCasinoBrandColors.White,

  Info: MyCasinoBrandColors.Black,
  InfoContrast: MyCasinoBrandColors.White,

  Attention: MyCasinoBrandColors.Primary,
  AttentionContrast: MyCasinoBrandColors.White,

  Danger: MyCasinoBrandColors.Alternate,
  DangerContrast: MyCasinoBrandColors.Black,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ElevationType = {
  Level1: `0 3px 5px var(--shadow-color, ${createTransparentColor(MyCasinoBrandColors.Black, 0.15)})`,
  Level2: '0 4px 8px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: MyCasinoBrandColors.Primary,
  BonusContrast: MyCasinoBrandColors.White,
};

const Layout: LayoutColors = {
  Background: MyCasinoBrandColors.Paper,
  BrandBorder: Primitive.Primary,
  BrowserTheme: MyCasinoBrandColors.Paper,
  LoadingColor: Primitive.Primary,
  Overlay: MyCasinoBrandColors.White + 'CC',
  Sidebar: {
    Background: MyCasinoBrandColors.Darker,
    Foreground: MyCasinoBrandColors.White,
  },
};

const Hero: HeroColorType = {
  Heading: MyCasinoBrandColors.White,
  Text: MyCasinoBrandColors.White,
  TextHighlight: MyCasinoBrandColors.Golden,

  Button: {
    Solid: '#d4af37',
    Rest: '#d4af37',
    Hover: MyCasinoBrandColors.Golden,
    Active: MyCasinoBrandColors.Golden,
    Text: Primitive.SecondaryContrast,
  },
};

export const Color = {
  ...MyCasinoBrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: MyCasinoBrandColors.Paper,

  // Shadows, highlight and transparency
  LighterHighlight: createTransparentColor(MyCasinoBrandColors.Light, 0.5),
  Highlight: createTransparentColor(MyCasinoBrandColors.Primary, 0.2),
  FocusHighlight: createTransparentColor(MyCasinoBrandColors.Primary, 0.33),
  AlternateFocusHighlight: createTransparentColor(MyCasinoBrandColors.Alternate, 0.33),
  SuccessFocusHighlight: createTransparentColor(MyCasinoBrandColors.Green, 0.33),
  GoldenHighlight: createTransparentColor(MyCasinoBrandColors.Golden, 0.33),
  Shadow: createTransparentColor(MyCasinoBrandColors.Black, 0.15),
  ShadowInset: createTransparentColor(MyCasinoBrandColors.White, 0.2),
  LightTransparent: createTransparentColor(MyCasinoBrandColors.White, 0.8),
  LightTransparent90: createTransparentColor(MyCasinoBrandColors.White, 0.9),
  DarkTransparent20: createTransparentColor(MyCasinoBrandColors.Black, 0.2),
  DarkTransparent50: createTransparentColor(MyCasinoBrandColors.Black, 0.5),
  DarkTransparent60: createTransparentColor(MyCasinoBrandColors.Black, 0.6),
  PrimaryTransparent80: createTransparentColor(MyCasinoBrandColors.Primary, 0.8),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart} ${ColorEnd})`,
  AdaptiveBackground: MyCasinoBrandColors.Primary,

  // Theme color for mobile browsers
  BrowserTheme: MyCasinoBrandColors.Paper,
};
